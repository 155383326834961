import { useSelector } from "react-redux";
import FallBackPage from "../../pages/fallBackPage/FallBackPage";
import { Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import navigationConstants from "../../navigationConstants";
import { useEffect } from "react";

const ProtectedRoutes = ({ children }) => {
  const { authMeta } = useSelector((state) => state.auth);
  const location = useLocation();

  useEffect(() => {
    if (!authMeta?.status) {
      localStorage.setItem("pathname", location?.pathname || "/");
    }
  }, [authMeta?.status]);

  if (authMeta?.status) {
    return <Suspense fallback={<FallBackPage />}>{children}</Suspense>;
  } else {
    return <Navigate to={navigationConstants.LOGIN} replace />;
  }
};
export default ProtectedRoutes;
