import FallBackPage from "./pages/fallBackPage/FallBackPage";
import navigationConstants from "./navigationConstants";
import ProtectedRoutes from "./components/protectedRoutes/ProtectedRoutes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import { useConfig } from "../customHooks/useConfig";
import { setFavicon } from "../utils/setFavicon";
import { useDispatch, useSelector } from "react-redux";
import {
  RESET_AUTH_STATE,
  SET_AUTH_META,
} from "../modules/auth/redux/authSlice";
import getCurrentUser from "../modules/auth/services/getCurrentUser";

const HomePage = lazy(() => import("../modules/home/pages/home/Home"));
const LoginPage = lazy(() => import("../modules/auth/pages/login/Login"));
const PageNotFoundPage = lazy(() =>
  import("./pages/PageNotFound/PageNotFound")
);

const TagConfigPage = lazy(() =>
  import("../modules/tasks/pages/TagConfigPage/TagConfigPage")
);
const TaskPage = lazy(() => import("../modules/tasks/pages/TaskPage/TaskPage"));
const TaskDetailsPage = lazy(() =>
  import("../modules/tasks/pages/TaskDetailsPage/TaskDetailsPage")
);

const ErrorPage = lazy(() => import("./pages/errorPage/ErrorPage"));

function AppRoutes() {
  let { config } = useConfig();
  const { authToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const checkIfTokenExpired = () => {
    getCurrentUser({
      onSuccess: (res) => {
        console.log("User is authenticated :", res);
        dispatch(
          SET_AUTH_META({
            username: res?.data?.userName,
            id: res?.data?.id,
            status: true,
          })
        );
      },
      onError: () => {
        console.log("User is not authenticated");
        dispatch(RESET_AUTH_STATE());
      },
    });
  };

  useEffect(() => {
    setFavicon(config?.assets?.favicon);
    if (!authToken) {
      dispatch(RESET_AUTH_STATE());
    } else {
      checkIfTokenExpired();
    }
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<FallBackPage />}>
        <Routes>
          <Route path={navigationConstants.LOGIN} element={<LoginPage />} />
          <Route
            path={navigationConstants.HOME}
            element={
              <ProtectedRoutes>
                <HomePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={navigationConstants.TASKS_BY_CONFIG}
            element={
              <ProtectedRoutes>
                <TaskPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={navigationConstants.SUBTASKS_BY_TASKID}
            element={
              <ProtectedRoutes>
                <TaskDetailsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={navigationConstants.TAGS_BY_CONFIG}
            element={
              <ProtectedRoutes>
                <TagConfigPage />
              </ProtectedRoutes>
            }
          />
          <Route path="*" element={<PageNotFoundPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );

  // const router = createBrowserRouter([
  //   {
  //     path: navigationConstants.LOGIN,
  //     element: (
  //       <Suspense fallback={<FallBackPage />}>
  //         <LoginPage />
  //       </Suspense>
  //     ),
  //     errorElement: <ErrorPage />,
  //   },
  //   {
  //     path: navigationConstants.HOME,
  //     element: (
  //       <ProtectedRoutes>
  //         <HomePage />
  //       </ProtectedRoutes>
  //     ),
  //     errorElement: <ErrorPage />,
  //   },
  //   {
  //     path: navigationConstants.TASKS_BY_BRANDNAME,
  //     element: (
  //       <ProtectedRoutes>
  //         <TaskPage />
  //       </ProtectedRoutes>
  //     ),
  //     errorElement: <ErrorPage />,
  //   },
  //   {
  //     path: navigationConstants.SUBTASKS_BY_TASKID,
  //     element: (
  //       <ProtectedRoutes>
  //         <TaskDetailsPage />
  //       </ProtectedRoutes>
  //     ),
  //     errorElement: <ErrorPage />,
  //   },
  // ]);

  // return <RouterProvider router={router} />;
}

export default AppRoutes;
